var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"width":"100px"}},[_vm._l((_vm.buttons.filter(function (f) { return f.visible; })),function(button){return _c('v-btn',{key:button.text,staticClass:"mt-3",attrs:{"text":""},on:{"click":function($event){_vm.sectionVisibility[button.link] = !_vm.sectionVisibility[button.link]}}},[_c('v-icon',{attrs:{"left":"","color":_vm.sectionVisibility[button.link] ? 'primary' : '#d3d3d3'}},[_vm._v(" "+_vm._s(button.icon)+" ")]),_c('span',{class:_vm.sectionVisibility[button.link] ? 'primary--text' : 'secondary--text'},[_vm._v(_vm._s(button.text))])],1)}),(_vm.usersData && _vm.usersData.length > 0 && _vm.sectionVisibility.users)?_c('div',{attrs:{"id":"users"}},[_c('v-toolbar',{staticClass:"mt-5",attrs:{"flat":"","dense":"","color":"info"}},[_c('v-toolbar-title',[_c('h5',[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountMultiple)+" ")]),_c('span',{staticClass:"white--text"},[_vm._v("Users With "+_vm._s(_vm.selectedRole.name)+" Grants")])],1)])],1),_c('div',{staticStyle:{"max-height":"325px","overflow-y":"auto"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.usersHeader,"items":_vm.usersData,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'snowflake-user', params : { id : _vm.parseIdForURL(item.user) } }}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"32"}},[(item.avatar)?_c('v-img',{attrs:{"src":require(("@/assets/images/avatars/" + (item.avatar)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.name || item.user)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_vm._v(" "+_vm._s(item.user)+" ")])],1)])]}}],null,true)})],1)],1):_vm._e(),(_vm.rolesFromSelectedRole && _vm.rolesFromSelectedRole.length > 0 && _vm.sectionVisibility.rolesFromSelectedRole)?_c('div',[_c('v-toolbar',{staticClass:"mt-5",attrs:{"id":"rolesFromSelectedRole","flat":"","color":"info","dense":""}},[_c('v-toolbar-title',[_c('h5',[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTableArrowRight)+" ")]),_c('span',{staticClass:"white--text"},[_vm._v("Roles With "+_vm._s(_vm.selectedRole.name)+" Grants")])],1)])],1),_c('div',{staticStyle:{"max-height":"325px","overflow-y":"auto"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.rolesHeader,"items":_vm.rolesFromSelectedRole,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiBadgeAccountOutline)+" ")]),_c('span',{staticClass:"d-flex flex-column ms-3 font-weight-bold"},[_vm._v(_vm._s(item.role))])],1)]}}],null,true)})],1)],1):_vm._e(),(_vm.rolesToSelectedRole && _vm.rolesToSelectedRole.length > 0 && _vm.sectionVisibility.rolesToSelectedRole)?_c('div',{attrs:{"id":"rolesToSelectedRole"}},[_c('v-toolbar',{staticClass:"mt-5",attrs:{"flat":"","color":"info","dense":""}},[_c('v-toolbar-title',[_c('h5',[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTableArrowLeft)+" ")]),_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.selectedRole.name)+" Has Grants To These Roles")])],1)])],1),_c('div',{staticStyle:{"max-height":"325px","overflow-y":"auto"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.rolesHeader,"items":_vm.rolesToSelectedRole,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiBadgeAccountOutline)+" ")]),_c('span',{staticClass:"d-flex flex-column ms-3 font-weight-bold"},[_vm._v(_vm._s(item.role))])],1)]}}],null,true)})],1)],1):_vm._e(),(_vm.rolePermissions && _vm.sectionVisibility.permissions)?_c('div',{attrs:{"id":"permissions"}},[_c('v-toolbar',{staticClass:"mt-5",attrs:{"flat":"","color":"info","dense":""}},[_c('v-toolbar-title',[_c('h5',[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiShieldLockOutline)+" ")]),_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.selectedRole.name)+" Permissions")])],1)])],1),(_vm.permissionData)?_c('div',{staticStyle:{"max-height":"100%","overflow-y":"auto"}},_vm._l((Object.keys(_vm.rolePermissions)),function(topGroup){return _c('v-list',{key:topGroup},[_c('v-list-group',{attrs:{"value":true,"prepend-icon":_vm.icons.mdiDatabaseOutline},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(topGroup)+" ")])]},proxy:true}],null,true)},_vm._l((Object.keys(_vm.rolePermissions[topGroup])),function(subGroup){return _c('v-list-group',{key:subGroup,attrs:{"value":true,"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiShieldLockOutline)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(subGroup))])],1)]},proxy:true}],null,true)},[_c('v-data-table',{staticClass:"ml-5",attrs:{"dense":"","headers":_vm.permissionHeaderGrouped,"items":_vm.rolePermissions[topGroup][subGroup],"items-per-page":-1,"hide-default-footer":"","calculate-widths":""},scopedSlots:_vm._u([{key:"item.grantedObject",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.isFutureGrant)?_c('v-chip',{staticClass:"info",attrs:{"x-small":""}},[_c('span',{staticClass:"white--text font-weight-bold"},[_vm._v("[FUTURE]")])]):_vm._e(),_c('span',{staticClass:"d-flex flex-column ms-3 font-weight-bold"},[_vm._v(_vm._s(item.grantedObject))])],1)]}}],null,true)})],1)}),1)],1)}),1):_vm._e()],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }