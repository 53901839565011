<template>
  <div>
    <v-row>
      <!-- Role List -->
      <v-col sm="4">
        <v-card>
          <v-row class="ml-1 mr-3">
            <v-card-title>
              <v-icon color="primary">
                {{ icons.mdiBadgeAccountOutline }}
              </v-icon>
              <span class="ml-3 primary--text font-weight-black">Snowflake Roles</span>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn
              text
              icon
              class="mt-2"
              @click="search = null; roleListType !== 'hier' ? roleListType = 'hier' : roleListType = 'list'"
            >
              <v-icon
                color="primary"
              >
                {{ roleListType === 'hier' ? icons.mdiFormatListBulleted : icons.mdiFamilyTree }}
              </v-icon>
            </v-btn>
            <v-btn
              text
              icon
              class="mt-2"
              @click="executeListExpandAll(open.length === 0 ? 'expand' : 'contract')"
            >
              <v-icon color="primary">
                {{ open.length === 0 ? icons.mdiExpandAllOutline : icons.mdiCollapseAllOutline }}
              </v-icon>
            </v-btn>
          </v-row>
          <v-divider class="primary"></v-divider>
        </v-card>
        <v-card
          max-height="750px"
          class="overflow-y-auto"
        >
          <v-row>
            <v-col>
              <div>
                <v-text-field
                  v-if="roleListType === 'hier'"
                  v-model="search"
                  class="ml-5"
                  label="Search Roles"
                  clearable
                  :prepend-inner-icon="icons.mdiMagnify"
                  :clear-icon="icons.mdiCloseCircleOutline"
                ></v-text-field>
                <v-treeview
                  hoverable
                  :items="roleListType === 'hier' ? roleHierarchy : roleList"
                  :search="search"
                  :open.sync="open"
                  :activatable="true"
                  :active="activeTreeItems"
                  open-on-click
                  color="primary"
                  selection-type="independent"
                  @update:active="treeSelected"
                  @update:open="treeOpened"
                >
                  <template
                    #prepend="{ item }"
                  >
                    <v-icon
                      v-if="item.children"
                    >
                      {{ icons.mdiBadgeAccountOutline }}
                    </v-icon>
                    <v-badge
                      v-if="!item.isParent"
                      class="ml-3"
                      inline
                      overlap
                      :content="item.props.assignedToUsers || '0'"
                    >
                    </v-badge>
                    <v-chip
                      v-if="item.isParent"
                      color="info"
                    >
                      <span class="font-weight-bold">In -></span>
                    </v-chip>
                  </template>
                  <template
                    #label="{ item }"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div>
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ item.name }}
                          </span>
                          <v-icon
                            v-if="item.topParent === item.name"
                            class="ml-3"
                            small
                          >
                            {{ icons.mdiFamilyTree }}
                          </v-icon>
                        </div>
                      </template>
                      <span>{{ item.name }}{{ item.props.comment ? ': ' : '' }}{{ item.props.comment }} | Granted To {{ item.props.assignedToUsers }} Users</span>
                    </v-tooltip>
                  </template>
                  <template
                    #append="{ item }"
                  >
                    <v-btn
                      v-if="item"
                      text
                      icon
                      @click="roleDialog = true"
                    >
                      <v-icon>
                        {{ icons.mdiEyeOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                </v-treeview>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- Role Hierarchies -->
      <v-col sm="8">
        <v-card
          height="800px"
        >
          <v-row class="mx-2">
            <v-card-title>
              <v-icon color="primary">
                {{ icons.mdiFamilyTree }}
              </v-icon>
              <span class="ml-3 primary--text font-weight-black">Role Hierarchies</span>
            </v-card-title>
            <v-spacer></v-spacer>
            <!-- Zoom Tools -->
            <div
              v-for="item in zoomTools"
              :key="item.name"
              class="mt-3"
            >
              <v-btn
                text
                icon
                @click="zoomExecution(item)"
              >
                <v-icon color="primary">
                  {{ item.icon }}
                </v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-divider class="primary"></v-divider>
          <v-row>
            <!-- Role Hierarchies Visualized -->
            <v-col sm="12">
              <div class="treeNodesContainer">
                <vue-tree
                  ref="tree"
                  style="width: 100%; height: 745px;"
                  :dataset="hierarchyVisData"
                  :config="treeConfig"
                  :collapse-enabled="false"
                >
                  <template v-slot:node="{ node, collapsed }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fixed
                          text
                          @click="currentlySelectedRole = node; roleDialog = true"
                        >
                          <div
                            v-bind="attrs"
                            class="role-nodes"
                            :class="node.name === currentlySelectedRole.name ? 'active-role-nodes' : 'inactive-role-nodes'"
                            :style="{ border: collapsed ? '2px solid grey' : '' }"
                            v-on="on"
                          >
                            <v-badge
                              inline
                              :content="node.props.assignedToUsers || '0'"
                            >
                              <template
                                #badge="{ }"
                              >
                                <div class="mt-1">
                                  <span>{{ node.props.assignedToUsers }}</span>
                                </div>
                              </template>
                              <span
                                :class="node.name === currentlySelectedRole.name ? 'white--text' : 'primary--text'"
                                class="caption font-weight-black"
                              >{{ node.name }}
                              </span>
                            </v-badge>
                          </div>
                        </v-btn>
                      </template>
                      <span>{{ node.name }}{{ node.props.comment ? ': ' : '' }}{{ node.props.comment }} | Granted To {{ node.props.assignedToUsers }} Users</span>
                    </v-tooltip>
                  </template>
                  <!-- <v-btn
                    class="ml-1"
                    text
                    icon
                    x-small
                    @click="currentlySelectedRole = node; roleDialog = true"
                  >
                    <v-icon>
                      {{ icons.mdiEyeOutline }}
                    </v-icon>
                  </v-btn> -->
                </vue-tree>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- Dialog: Role Overview -->
    <v-navigation-drawer
      v-if="roleDialog"
      :value="roleDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 850 : '100%'"
      temporary
      app
      @input="(val) => roleDialog = val"
    >
      <v-card>
        <v-toolbar
          flat
          class="primary"
        >
          <v-btn
            icon
            @click="roleDialog = false"
          >
            <v-icon color="white">
              {{ icons.mdiCloseCircleOutline }}
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="white--text font-weight-black">
              {{ currentlySelectedRole.name }}: Role Profile
            </span>
          </v-toolbar-title>
        </v-toolbar>
        <snowflake-roles-profile></snowflake-roles-profile>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { buildRoleHierarchy } from '@/snowflake/snowflakeAdministration'
import store from '@/store'
// eslint-disable-next-line object-curly-newline
import { mdiBadgeAccountHorizontal, mdiBadgeAccountOutline, mdiCloseCircleOutline, mdiCollapseAllOutline, mdiExpandAllOutline, mdiEyeOutline, mdiFamilyTree, mdiFormatListBulleted, mdiFullscreen, mdiMagnify, mdiMagnifyMinusOutline, mdiMagnifyPlusOutline } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import { computed, getCurrentInstance, onMounted, provide, ref, watch } from '@vue/composition-api'
import _ from 'lodash'
import SnowflakeRolesProfile from './SnowflakeRolesProfile.vue'

export default {
  components: {
    SnowflakeRolesProfile,
  },
  setup() {
    const open = ref([])
    const search = ref(null)
    const caseSensitive = ref(false)
    const activeTreeItems = ref([1])
    const treeRef = getCurrentInstance()?.proxy.$refs
    const allListedValues = ref([])
    const currentlyOpenTreeItems = ref([])
    const currentlySelectedRole = ref(null)
    const hierarchyVisData = ref([])
    const roleDialog = ref(false)
    const roleListType = ref('hier') // hier or list

    const roles = computed(() => store.state.snowflakeData.snowflakeRoles)

    provide('selectedRole', currentlySelectedRole)

    const treeSelected = itemArray => {
      const selectedRole = allListedValues.value.filter(f => f.id === itemArray[0])[0]
      currentlySelectedRole.value = selectedRole

      return selectedRole.name
    }
    const treeOpened = itemArray => {
      if (itemArray.length - currentlyOpenTreeItems.value.length === 1) {
        activeTreeItems.value = itemArray.slice(-1)

        // Initiate The Following Role in Hierarchy
        const openedRole = allListedValues.value.filter(f => f.id === itemArray.slice(-1)[0])[0]
        currentlySelectedRole.value = openedRole
      } else {
        // Do Nothing - As a value was closed
      }

      // Set Array Back To Watch
      currentlyOpenTreeItems.value = [...itemArray]

      return null
    }
    const executeListExpandAll = type => {
      if (type === 'expand') {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < allListedValues.value.length; i++) open.value.push(i)
      } else {
        open.value = []
      }
    }

    const zoomTools = [
      { name: 'Zoom In', icon: mdiMagnifyPlusOutline },
      { name: 'Zoom Out', icon: mdiMagnifyMinusOutline },
      { name: 'Center', icon: mdiFullscreen },
    ]

    const zoomExecution = item => {
      if (item.name === 'Zoom In') {
        treeRef.tree.zoomIn()
      } else if (item.name === 'Zoom Out') {
        treeRef.tree.zoomOut()
      } else {
        treeRef.tree.restoreScale()
      }
    }

    const roleHierarchy = computed(() => {
      let idCount = 0
      const hierarchyArray = []
      const { objectHier } = buildRoleHierarchy(roles.value)
      delete objectHier.undefined

      // to Do: Adding Props to Each Hier to add to tooltip
      Object.keys(objectHier).forEach((l1, i1) => {
        idCount += 1
        const p1 = roles.value.filter(f => f.name === l1)[0]
        hierarchyArray.push({
          name: l1,
          id: idCount,
          children: [],
          props: p1,
          topParent: l1,
        })
        allListedValues.value.push({
          name: l1,
          id: idCount,
          props: p1,
          topParent: l1,
        })
        Object.keys(objectHier[l1]).forEach((l2, i2) => {
          idCount += 1
          const p2 = roles.value.filter(f => f.name === l2)[0]
          hierarchyArray[i1].children.push({
            name: l2,
            id: idCount,
            children: [],
            props: p2,
            topParent: l1,
          })
          allListedValues.value.push({
            name: l2,
            id: idCount,
            props: p2,
            topParent: l1,
          })
          Object.keys(objectHier[l1][l2]).forEach((l3, i3) => {
            idCount += 1
            const p3 = roles.value.filter(f => f.name === l3)[0]
            hierarchyArray[i1].children[i2].children.push({
              name: l3,
              id: idCount,
              children: [],
              props: p3,
              topParent: l1,
            })
            allListedValues.value.push({
              name: l3,
              id: idCount,
              props: p3,
              topParent: l1,
            })
            Object.keys(objectHier[l1][l2][l3]).forEach((l4, i4) => {
              idCount += 1
              const p4 = roles.value.filter(f => f.name === l4)[0]
              hierarchyArray[i1].children[i2].children[i3].children.push({
                name: l4,
                id: idCount,
                children: [],
                props: p4,
                topParent: l1,
              })
              allListedValues.value.push({
                name: l4,
                id: idCount,
                props: p4,
                topParent: l1,
              })
              Object.keys(objectHier[l1][l2][l3][l4]).forEach((l5, i5) => {
                idCount += 1
                const p5 = roles.value.filter(f => f.name === l5)[0]
                hierarchyArray[i1].children[i2].children[i3].children[i4].children.push({
                  name: l5,
                  id: idCount,
                  children: [],
                  props: p5,
                  topParent: l1,
                })
                allListedValues.value.push({
                  name: l5,
                  id: idCount,
                  props: p5,
                  topParent: l1,
                })
                Object.keys(objectHier[l1][l2][l3][l4][l5]).forEach((l6, i6) => {
                  idCount += 1
                  const p6 = roles.value.filter(f => f.name === l6)[0]
                  hierarchyArray[i1].children[i2].children[i3].children[i4].children[i5].children.push({
                    name: l6,
                    id: idCount,
                    children: [],
                    props: p6,
                    topParent: l1,
                  })
                  allListedValues.value.push({
                    name: l6,
                    id: idCount,
                    props: p6,
                    topParent: l1,
                  })
                  Object.keys(objectHier[l1][l2][l3][l4][l5][l6]).forEach((l7, i7) => {
                    idCount += 1
                    const p7 = roles.value.filter(f => f.name === l7)[0]
                    hierarchyArray[i1].children[i2].children[i3].children[i4].children[i5].children[i6].children.push({
                      name: l7,
                      id: idCount,
                      children: [],
                      props: p7,
                      topParent: l1,
                    })
                    allListedValues.value.push({
                      name: l7,
                      id: idCount,
                      props: p7,
                      topParent: l1,
                    })
                    Object.keys(objectHier[l1][l2][l3][l4][l5][l6][l7]).forEach((l8, i8) => {
                      idCount += 1
                      const p8 = roles.value.filter(f => f.name === l8)[0]
                      hierarchyArray[i1].children[i2].children[i3].children[i4].children[i5].children[i6].children[i7].children.push({
                        name: l8,
                        id: idCount,
                        children: [],
                        props: p8,
                        topParent: l1,
                      })
                      allListedValues.value.push({
                        name: l8,
                        id: idCount,
                        props: p8,
                        topParent: l1,
                      })
                      Object.keys(objectHier[l1][l2][l3][l4][l5][l6][l7]).forEach(l9 => {
                        idCount += 1
                        const p9 = roles.value.filter(f => f.name === l9)[0]
                        hierarchyArray[i1].children[i2].children[i3].children[i4].children[i5].children[i6].children[i7].children[i8].children.push({
                          name: l9,
                          id: idCount,
                          children: [],
                          props: p9,
                          topParent: l1,
                        })
                        allListedValues.value.push({
                          name: l9,
                          id: idCount,
                          props: p9,
                          topParent: l1,
                        })
                      })
                    })
                  })
                })
              })
            })
          })
        })
      })
      console.log('hierarchyArray', hierarchyArray)

      return hierarchyArray
    })

    const roleList = computed(() => {
      const roleArray = []
      const hierData = roleHierarchy.value.map(m => m)
      function iterateOnObjects(object) {
        object.forEach(row => {
          roleArray.push(row)
          if (row.children && row.children.length > 0) {
            iterateOnObjects(row.children)
          }
        })
      }
      function removeChildren(object) {
        const { children, ...newObject } = object

        return newObject
      }
      if (hierData) {
        iterateOnObjects(hierData)
      }

      // Make The Top Parents Children
      const rolesWithParent = []
      _.orderBy(roleArray, ['topParent', 'name']).forEach(roleObject => {
        const role = removeChildren(roleObject)
        const currentArrayRoles = rolesWithParent.map(m => m.name)
        const getParentData = roleArray.filter(f => f.name === role.topParent)[0]
        const parentData = removeChildren(getParentData)
        if (!currentArrayRoles.includes(role.name)) {
          let roleData = {}
          if (role.name !== parentData.name) {
            roleData = { ...role, children: [{ ...parentData, isParent: true }] }
          } else {
            roleData = { ...role }
          }
          rolesWithParent.push(roleData)
        } else {
          const roleIndex = rolesWithParent.findIndex(i => i.name === role.name)
          const childrenNames = rolesWithParent[roleIndex].children.map(m => m.name)
          if (!childrenNames.includes(role.topParent)) {
            rolesWithParent[roleIndex].children.push({ ...parentData, isParent: true })
          }
        }
      })

      console.log('roleHierarchy', roleHierarchy.value)
      console.log('rolesWithParent', rolesWithParent)

      return _.orderBy(rolesWithParent, 'name')
    })

    // Vis Data Driving the Hierarchy Visualization (Filtered On Role Selection)
    const refreshHierarchyVisData = () => {
      const data = roleHierarchy.value
      const visData = data.filter(f => f.name === currentlySelectedRole?.value?.topParent || f.name === currentlySelectedRole?.value?.name)
      if (visData && Object.keys(visData).length > 0) {
        hierarchyVisData.value = visData
      }
    }

    watch([currentlySelectedRole], () => {
      refreshHierarchyVisData()
      console.log('Hier Data', roleHierarchy.value)
    })

    onMounted(() => {
      const data = roleHierarchy.value
      currentlySelectedRole.value = { id: data[0].id, name: data[0].name }
      refreshHierarchyVisData()
    })

    return {
      roleList,
      roleListType,
      roleDialog,
      currentlySelectedRole,
      hierarchyVisData,
      treeOpened,
      treeSelected,
      activeTreeItems,
      executeListExpandAll,
      roleHierarchy,
      open,
      search,
      caseSensitive,
      // eslint-disable-next-line object-curly-newline
      icons: { mdiFormatListBulleted, mdiCloseCircleOutline, mdiMagnify, mdiBadgeAccountOutline, mdiExpandAllOutline, mdiCollapseAllOutline, mdiFamilyTree, mdiBadgeAccountHorizontal, mdiEyeOutline },
      zoomTools,
      zoomExecution,
      treeConfig: { nodeWidth: 280, nodeHeight: 40, levelHeight: 200 },
    }
  },
}
</script>

<style scoped lang="scss">
.treeNodesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.role-nodes {
  width: 250px;
  padding: 8px;
  display: flex;
  // flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  border-radius: 4px;
}

.inactive-role-nodes {
  background-color: #f2ebff;
}

.active-role-nodes {
  background-color: #f9b401;
}
</style>
