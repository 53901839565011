var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"sm":"4"}},[_c('v-card',[_c('v-row',{staticClass:"ml-1 mr-3"},[_c('v-card-title',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiBadgeAccountOutline)+" ")]),_c('span',{staticClass:"ml-3 primary--text font-weight-black"},[_vm._v("Snowflake Roles")])],1),_c('v-spacer'),_c('v-btn',{staticClass:"mt-2",attrs:{"text":"","icon":""},on:{"click":function($event){_vm.search = null; _vm.roleListType !== 'hier' ? _vm.roleListType = 'hier' : _vm.roleListType = 'list'}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.roleListType === 'hier' ? _vm.icons.mdiFormatListBulleted : _vm.icons.mdiFamilyTree)+" ")])],1),_c('v-btn',{staticClass:"mt-2",attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.executeListExpandAll(_vm.open.length === 0 ? 'expand' : 'contract')}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.open.length === 0 ? _vm.icons.mdiExpandAllOutline : _vm.icons.mdiCollapseAllOutline)+" ")])],1)],1),_c('v-divider',{staticClass:"primary"})],1),_c('v-card',{staticClass:"overflow-y-auto",attrs:{"max-height":"750px"}},[_c('v-row',[_c('v-col',[_c('div',[(_vm.roleListType === 'hier')?_c('v-text-field',{staticClass:"ml-5",attrs:{"label":"Search Roles","clearable":"","prepend-inner-icon":_vm.icons.mdiMagnify,"clear-icon":_vm.icons.mdiCloseCircleOutline},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-treeview',{attrs:{"hoverable":"","items":_vm.roleListType === 'hier' ? _vm.roleHierarchy : _vm.roleList,"search":_vm.search,"open":_vm.open,"activatable":true,"active":_vm.activeTreeItems,"open-on-click":"","color":"primary","selection-type":"independent"},on:{"update:open":[function($event){_vm.open=$event},_vm.treeOpened],"update:active":_vm.treeSelected},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [(item.children)?_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiBadgeAccountOutline)+" ")]):_vm._e(),(!item.isParent)?_c('v-badge',{staticClass:"ml-3",attrs:{"inline":"","overlap":"","content":item.props.assignedToUsers || '0'}}):_vm._e(),(item.isParent)?_c('v-chip',{attrs:{"color":"info"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("In ->")])]):_vm._e()]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")]),(item.topParent === item.name)?_c('v-icon',{staticClass:"ml-3",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFamilyTree)+" ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name)+_vm._s(item.props.comment ? ': ' : '')+_vm._s(item.props.comment)+" | Granted To "+_vm._s(item.props.assignedToUsers)+" Users")])])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(item)?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){_vm.roleDialog = true}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1):_vm._e()]}}])})],1)])],1)],1)],1),_c('v-col',{attrs:{"sm":"8"}},[_c('v-card',{attrs:{"height":"800px"}},[_c('v-row',{staticClass:"mx-2"},[_c('v-card-title',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFamilyTree)+" ")]),_c('span',{staticClass:"ml-3 primary--text font-weight-black"},[_vm._v("Role Hierarchies")])],1),_c('v-spacer'),_vm._l((_vm.zoomTools),function(item){return _c('div',{key:item.name,staticClass:"mt-3"},[_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.zoomExecution(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1)],1)})],2),_c('v-divider',{staticClass:"primary"}),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"treeNodesContainer"},[_c('vue-tree',{ref:"tree",staticStyle:{"width":"100%","height":"745px"},attrs:{"dataset":_vm.hierarchyVisData,"config":_vm.treeConfig,"collapse-enabled":false},scopedSlots:_vm._u([{key:"node",fn:function(ref){
var node = ref.node;
var collapsed = ref.collapsed;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"fixed":"","text":""},on:{"click":function($event){_vm.currentlySelectedRole = node; _vm.roleDialog = true}}},[_c('div',_vm._g(_vm._b({staticClass:"role-nodes",class:node.name === _vm.currentlySelectedRole.name ? 'active-role-nodes' : 'inactive-role-nodes',style:({ border: collapsed ? '2px solid grey' : '' })},'div',attrs,false),on),[_c('v-badge',{attrs:{"inline":"","content":node.props.assignedToUsers || '0'},scopedSlots:_vm._u([{key:"badge",fn:function(ref){return [_c('div',{staticClass:"mt-1"},[_c('span',[_vm._v(_vm._s(node.props.assignedToUsers))])])]}}],null,true)},[_c('span',{staticClass:"caption font-weight-black",class:node.name === _vm.currentlySelectedRole.name ? 'white--text' : 'primary--text'},[_vm._v(_vm._s(node.name)+" ")])])],1)])]}}],null,true)},[_c('span',[_vm._v(_vm._s(node.name)+_vm._s(node.props.comment ? ': ' : '')+_vm._s(node.props.comment)+" | Granted To "+_vm._s(node.props.assignedToUsers)+" Users")])])]}}])})],1)])],1)],1)],1)],1),(_vm.roleDialog)?_c('v-navigation-drawer',{attrs:{"value":_vm.roleDialog,"touchless":"","right":!_vm.$vuetify.rtl,"width":_vm.$vuetify.breakpoint.smAndUp ? 850 : '100%',"temporary":"","app":""},on:{"input":function (val) { return _vm.roleDialog = val; }}},[_c('v-card',[_c('v-toolbar',{staticClass:"primary",attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.roleDialog = false}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCloseCircleOutline)+" ")])],1),_c('v-toolbar-title',[_c('span',{staticClass:"white--text font-weight-black"},[_vm._v(" "+_vm._s(_vm.currentlySelectedRole.name)+": Role Profile ")])])],1),_c('snowflake-roles-profile')],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }