<template>
  <div width="100px">
    <v-btn
      v-for="button in buttons.filter(f => f.visible)"
      :key="button.text"
      class="mt-3"
      text
      @click="sectionVisibility[button.link] = !sectionVisibility[button.link]"
    >
      <v-icon
        left
        :color="sectionVisibility[button.link] ? 'primary' : '#d3d3d3'"
      >
        {{ button.icon }}
      </v-icon>
      <span :class="sectionVisibility[button.link] ? 'primary--text' : 'secondary--text'">{{ button.text }}</span>
      <!-- <a :href="`#${button.link}`">
        <v-icon left>
          {{ button.icon }}
        </v-icon>{{ button.text }}
      </a> -->
    </v-btn>

    <!-- Grants To Users -->
    <!-- --------------- -->
    <div
      v-if="usersData && usersData.length > 0 && sectionVisibility.users"
      id="users"
    >
      <v-toolbar
        class="mt-5"
        flat
        dense
        color="info"
      >
        <v-toolbar-title>
          <h5>
            <v-icon
              left
              color="white"
            >
              {{ icons.mdiAccountMultiple }}
            </v-icon>
            <span class="white--text">Users With {{ selectedRole.name }} Grants</span>
          </h5>
        </v-toolbar-title>
      </v-toolbar>
      <div style="max-height:325px; overflow-y: auto">
        <v-data-table
          dense
          :headers="usersHeader"
          :items="usersData"
          :items-per-page="-1"
          hide-default-footer
        >
          <!-- name -->
          <template #[`item.user`]="{item}">
            <router-link
              :to="{ name : 'snowflake-user', params : { id : parseIdForURL(item.user) } }"
              class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              <div class="d-flex align-center">
                <v-avatar
                  :color="item.avatar ? '' : 'primary'"
                  :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                  size="32"
                >
                  <v-img
                    v-if="item.avatar"
                    :src="require(`@/assets/images/avatars/${item.avatar}`)"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium"
                  >{{ avatarText(item.name || item.user) }}</span>
                </v-avatar>

                <div class="d-flex flex-column ms-3">
                  {{ item.user }}
                </div>
              </div>
            </router-link>
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- Grants To Roles -->
    <!-- --------------- -->
    <div v-if="rolesFromSelectedRole && rolesFromSelectedRole.length > 0 && sectionVisibility.rolesFromSelectedRole">
      <v-toolbar
        id="rolesFromSelectedRole"
        class="mt-5"
        flat
        color="info"
        dense
      >
        <v-toolbar-title>
          <h5>
            <v-icon
              left
              color="white"
            >
              {{ icons.mdiTableArrowRight }}
            </v-icon>
            <span class="white--text">Roles With {{ selectedRole.name }} Grants</span>
          </h5>
        </v-toolbar-title>
      </v-toolbar>
      <div style="max-height:325px; overflow-y: auto">
        <v-data-table
          dense
          :headers="rolesHeader"
          :items="rolesFromSelectedRole"
          :items-per-page="-1"
          hide-default-footer
        >
          <template #[`item.role`]="{item}">
            <div class="d-flex align-center">
              <v-icon left>
                {{ icons.mdiBadgeAccountOutline }}
              </v-icon>
              <span class="d-flex flex-column ms-3 font-weight-bold">{{ item.role }}</span>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- Grant Access To Roles -->
    <!-- --------------- -->
    <div
      v-if="rolesToSelectedRole && rolesToSelectedRole.length > 0 && sectionVisibility.rolesToSelectedRole"
      id="rolesToSelectedRole"
    >
      <v-toolbar
        class="mt-5"
        flat
        color="info"
        dense
      >
        <v-toolbar-title>
          <h5>
            <v-icon
              left
              color="white"
            >
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
            <span class="white--text">{{ selectedRole.name }} Has Grants To These Roles</span>
          </h5>
        </v-toolbar-title>
      </v-toolbar>
      <div style="max-height:325px; overflow-y: auto">
        <v-data-table
          dense
          :headers="rolesHeader"
          :items="rolesToSelectedRole"
          :items-per-page="-1"
          hide-default-footer
        >
          <template #[`item.role`]="{item}">
            <div class="d-flex align-center">
              <v-icon left>
                {{ icons.mdiBadgeAccountOutline }}
              </v-icon>
              <span class="d-flex flex-column ms-3 font-weight-bold">{{ item.role }}</span>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- Grant Permissions -->
    <!-- ----------------- -->
    <div
      v-if="rolePermissions && sectionVisibility.permissions"
      id="permissions"
    >
      <v-toolbar
        class="mt-5"
        flat
        color="info"
        dense
      >
        <v-toolbar-title>
          <h5>
            <v-icon
              left
              color="white"
            >
              {{ icons.mdiShieldLockOutline }}
            </v-icon>
            <span class="white--text">{{ selectedRole.name }} Permissions</span>
          </h5>
        </v-toolbar-title>
      </v-toolbar>
      <div
        v-if="permissionData"
        style="max-height:100%; overflow-y: auto"
      >
        <v-list
          v-for="topGroup in Object.keys(rolePermissions)"

          :key="topGroup"
        >
          <v-list-group
            :value="true"
            :prepend-icon="icons.mdiDatabaseOutline"
          >
            <template #activator>
              <v-list-item-title class="font-weight-bold">
                {{ topGroup }}
              </v-list-item-title>
            </template>

            <v-list-group
              v-for="subGroup in Object.keys(rolePermissions[topGroup])"
              :key="subGroup"
              :value="true"
              no-action
              sub-group
            >
              <template #activator>
                <v-icon left>
                  {{ icons.mdiShieldLockOutline }}
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ subGroup }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-data-table
                class="ml-5"
                dense
                :headers="permissionHeaderGrouped"
                :items="rolePermissions[topGroup][subGroup]"
                :items-per-page="-1"
                hide-default-footer
                calculate-widths
              >
                <template #[`item.grantedObject`]="{item}">
                  <div class="d-flex align-center">
                    <v-chip
                      v-if="item.isFutureGrant"
                      x-small
                      class="info"
                    >
                      <span class="white--text font-weight-bold">[FUTURE]</span>
                    </v-chip>
                    <span class="d-flex flex-column ms-3 font-weight-bold">{{ item.grantedObject }}</span>
                  </div>
                </template>
              </v-data-table>
            </v-list-group>
          </v-list-group>
        </v-list>
        <!-- <v-data-table
          dense
          :headers="permissionHeader"
          :items="rolePermissions"
          :items-per-page="-1"
          group-by="grantedType"
          hide-default-footer
        >
        </v-data-table> -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import { firestoreTimestamps, readDocs } from '@/firestore'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line object-curly-newline
import { mdiAccountMultiple, mdiBadgeAccountOutline, mdiDatabaseOutline, mdiShieldAccountOutline, mdiShieldLockOutline, mdiTableArrowLeft, mdiTableArrowRight } from '@mdi/js'
import { computed, inject, onMounted, ref } from '@vue/composition-api'
import _ from 'lodash'
import moment from 'moment-timezone'

export default {
  setup() {
    const selectedRole = inject('selectedRole')
    const sectionVisibility = ref({ users: true, rolesFromSelectedRole: true, rolesToSelectedRole: true, permissions: true })
    const parseIdForURL = id => encodeURI(id)
    const snowflakeRoles = computed(() => store.state.snowflakeData.snowflakeRoles)
    const rolePermissions = ref(null)
    const groupPermissionBy = ref('object')

    const usersData = computed(() => {
      const roles = store.state.snowflakeData.snowflakeUserRoles
      const { snowflakeUsers } = store.state.snowflakeData
      const users = roles.filter(f => f.role === selectedRole.value.name).map(m => ({ user: m.granteeName, grantedDate: moment(m.createdOn).format('YYYY/MM/DD'), grantedBy: m.grantedBy }))

      // Get User Name and Avatar if Exists
      users.forEach((user, i) => {
        const userMatch = snowflakeUsers.filter(f => f.id === user.user)[0]
        users[i] = { ...users[i], name: userMatch.displayName, avatar: userMatch.avatar || null }
      })

      return users
    })

    const rolesFromSelectedRole = computed(() => {
      const roles = []
      const getRoles = snowflakeRoles.value.filter(f => f.name === selectedRole.value.name)[0]
      getRoles.granteeRoles.forEach(grantee => {
        roles.push({ role: grantee.role, grantedDate: moment(grantee.grantedAt).format('YYYY/MM/DD'), grantedBy: grantee.grantedBy })
      })

      return roles
    })

    const rolesToSelectedRole = computed(() => {
      const roles = []
      snowflakeRoles.value.forEach(role => {
        role.granteeRoles.forEach(grantee => {
          if (grantee.role === selectedRole.value.name) {
            roles.push({ ...role, grantedAt: grantee.grantedAt, grantedBy: grantee.grantedBy })
          }
        })
      })

      return roles.map(m => ({ role: m.name, grantedDate: moment(m.grantedAt).format('YYYY/MM/DD'), grantedBy: m.grantedBy }))
    })

    const permissionData = async () => {
      const getRoleGrants = await readDocs({ collection: 'snowflakeGrants', where: [['name', '==', selectedRole.value.name]] })
      let permissions = firestoreTimestamps(getRoleGrants[0].grants)
      permissions = permissions.map(m => ({ privilege: m.privilege, grantedType: m.grantedOn || m.grantOn, grantedObject: m.name, grantedDate: moment(m.createdOn).format('YYYY/MM/DD'), grantedBy: m.grantedBy || null, isFutureGrant: m.isFutureGrant || null }))
      if (groupPermissionBy.value === 'object') {
        permissions = _.orderBy(permissions, 'grantedDate')
        permissions = _.groupBy(permissions, 'grantedType')
        const permissionsObject = {}
        Object.keys(permissions).forEach(key => {
          permissionsObject[key] = _.groupBy(permissions[key], 'privilege')
        })
        permissions = permissionsObject
      }
      rolePermissions.value = permissions
    }

    onMounted(() => {
      permissionData()
    })

    const buttons = computed(() => [
      { text: 'User Grants', link: 'users', icon: mdiAccountMultiple, visible: usersData.value && usersData.value.length > 0 },
      { text: 'Roles With Grants', link: 'rolesFromSelectedRole', icon: mdiBadgeAccountOutline, visible: rolesFromSelectedRole.value && rolesFromSelectedRole.value.length > 0 },
      { text: 'Grants To Other Roles', link: 'rolesToSelectedRole', icon: mdiBadgeAccountOutline, visible: rolesToSelectedRole.value && rolesToSelectedRole.value.length > 0 },
      { text: 'Permissions', link: 'permissions', icon: mdiShieldLockOutline, visible: rolePermissions.value },
    ])

    return {
      permissionData,
      sectionVisibility,
      buttons,
      rolePermissions,
      selectedRole,
      parseIdForURL,
      usersData,
      rolesFromSelectedRole,
      rolesToSelectedRole,
      usersHeader: [
        { text: 'User', value: 'user' },
        { text: 'Granted Date', value: 'grantedDate' },
        { text: 'Granted By', value: 'grantedBy' },
        { text: 'Actions', value: 'actions' },
      ],
      rolesHeader: [
        { text: 'Role', value: 'role' },
        { text: 'Granted Date', value: 'grantedDate' },
        { text: 'Granted By', value: 'grantedBy' },
        { text: 'Actions', value: 'actions' },
      ],
      permissionHeader: [
        { text: 'Privilege', value: 'privilege' },
        { text: 'Granted Type', value: 'grantedType' },
        { text: 'Granted Object', value: 'grantedObject' },
        { text: 'Granted Date', value: 'grantedDate' },
        { text: 'Granted By', value: 'grantedBy' },
        { text: 'Actions', value: 'actions' },
      ],
      permissionHeaderGrouped: [
        { text: 'Granted Object', value: 'grantedObject', width: '50' },
        { text: 'Granted Date', value: 'grantedDate', width: '25%' },
        { text: 'Granted By', value: 'grantedBy', width: '25%' },
        { text: 'Actions', value: 'actions', width: '25%' },
      ],
      avatarText,
      icons: {
        mdiDatabaseOutline,
        mdiAccountMultiple,
        mdiTableArrowLeft,
        mdiTableArrowRight,
        mdiBadgeAccountOutline,
        mdiShieldAccountOutline,
        mdiShieldLockOutline,
      },
    }
  },
}
</script>

  <style>
a {
  text-decoration: none;
}
</style>
